#wrapper.pt_checkout {
    #main {
        #primary {
            .header_prompt {
                margin-bottom: 16px;
                @include respond(small) {
                    margin-top: 16px;
                }
            }
            .corrections,
            .right_details {
                float: left;
                width: 50%;
                .form-row {
                    label {
                        width: 90%;
                    }
                    .field-wrapper {
                        width: 90%;
                    }
                    .value {
                        line-height: 34px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}