.ui-dialog {
    &.gtm-terms {
        &.ui-dialog {
            .ui-dialog-titlebar {
                line-height: 10px;
                .ui-dialog-titlebar-close {
                    top: 20px;
                }
            }
            #gtm-terms-dialog {
                padding: 0 1em .5em 1em !important;
                h1 {
                    line-height: 1.2rem;
                }
                .titleist-btn {
                    font-weight: 700;
                    text-decoration: none;
                    padding: 12px 20px;
                    border-radius: 2px;
                    -webkit-font-smoothing: antialiased;
                    cursor: pointer;
                    font-family: $sans-serif;
                    line-height: normal;
                    min-width: 80px;
                    text-align: center;
                    box-sizing: border-box;
                    font-size: rem(14px);
                    display: inline-block;
                    vertical-align: baseline;
                    zoom: 1;
                    transition: all linear 0.1s;
                    border: 1px solid $color-secondary;
                    background: $color-secondary;
                    color: $white;
                    &:hover {
                        color: #fff;
                        text-decoration: none;
                        background: #b5132c;
                        border-color: #b5132c;
                    }
                }
            }
        }
    }
}
