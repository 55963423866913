#wrapper.pt_checkout {
    #main #primary {
        .checkout-shipping {
            &.form-horizontal {
                .form-row {
                    .form-caption {
                        padding-left: 0;
                    }
                }
            }
            .form-field-tooltip {
                position: absolute;
                left: auto;
                right: 0;
                width: auto;
                .postcode-lookup {
                    font-weight: 700;
                    color: $black;
                }
                @include respond(small) {
                    top: 8px;
                }
            }
            .form-row.required {
                label {
                    span {
                        &:not(.required-indicator) {
                            @include respond(large) {
                                display: block;
                                height: 32px;
                            }
                        }
                    }
                }
            }
            .useAsBillingAddress + label {
                margin-top: 16px;
            }
			.form-row {
				#daum-postcodes-drowdown {
					div[id^="__daum__layer"] {
						@media screen and (max-width: 479px) {
							overflow: visible !important;
							width: 120% !important;
							transform: scale(0.8);
							iframe {
								transform: translateX(-12.5%);
								width: 104% !important;
							}
						}
						@media screen and (min-width: 480px) {
							width: 100% !important;
						}
					}
				}
			}
            .form-row--dwfrm_singleshipping_shippingAddress_addressFields_confirmshipping {
                padding: 7px 0 10px;
                margin-top: -8px;
                .error {
                    display: block !important;
                    padding: 20px 0 0;
                }
                .error + label {
                    position: absolute;
                    top: 8px;
                    left: 13px;
                }
            }
        }
        .checkout-progress-indicator {
            > div {
                padding: 0 8px;
            }
            &.multiship {
                > div {
                    width: 25%;
                    a, .name {
                        @include respond(small) {
                            font-size: 10px;
                            letter-spacing: -.025rem;
                        }
                        @include respond(iphone) {
                            font-size: 10px;
                            letter-spacing: -.025rem;
                        }
                    }
                }
            }
        }
        .summary {
            .summary-address {
                > div {
                    width: 100%;
                }
            }
        }
    }
}
