////////////////////////
// Checkout Billing Step
////////////////////////

#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-billing {
				.form-row {
					position: relative;
                    @include respond(small) {
                        margin: 0;
                    }
                    &.required {
                        label {
                            span {
                                &:after {
                                    content: " *";
                                    color: $gray-dark;
                                }
                                &.required-indicator {
                                    display: none;
                                }
                            }
                        }
                    }
                    label {
                        height: 34px;
                        line-height: normal;
                        margin-top: 0;
                        padding-top: 9px;
                        box-sizing: border-box;
                        @include respond(large) {
                            width: 26%;
                        }
                    }
                    .field-wrapper {
                        @include respond(large) {
                            width: 74%;
                        }
                    }
                    .form-caption {
                        color: $gray-warm-old;
                        font-size: 11px;
                        @include respond(large) {
                            margin-left: 26%;
                        }
                    }
                    &.month {
                        @include respond(small) {
                            margin-right: 4%;
                        }
                    }
                    &.month,
                    &.year {
                        .field-wrapper {
                            @include respond(large) {
                                width: 100%;
                            }
                        }
                    }
                    &.cvn {
                        .form-field-tooltip {
							left: auto;
							margin: rem(16px) 0 0 rem(8px);
							position: relative;
							top: 0;
							float: left;
							width: auto;
                            @include respond(large) {
                                left: auto;
								position: relative;
								top: 0;
								margin: rem(16px) 0 0 rem(8px);
								float: left;
								width: auto;
                            }
                        }
                    }
                }
                .form-row-button {
                    padding: 0 rem(16px) rem(28px);

                }
				.form-field-tooltip {
					left: 356px;
					position: absolute;
					top: 2px;
					padding-top: 0;
					a {
					    font-size: 11px;
					}
                    @include respond(small) {
                        width: auto;
                        left: auto;
                        right: 0;
                        top: 8px;
                    }
				}
				.fieldset {
					> .payment-method-options.form-indent {
						.form-row {
							&.Adyen {
								display: none;
							}
							.input-radio,
							#is-CREDIT_CARD {
								margin: 10px 8px 0 0;
							}
							label {
								padding-left: 0;
								margin-right: 8px;
                                width: auto;
                                @include respond(small) {
                                    overflow: visible;
                                }
							}
							span.icon {
								width: 100%;
								text-align: center;
                                @include respond(small) {
                                    text-align: left;
                                }
								background: none;
								margin: 3px 0 0;
								@include respond(large) {
									height: 17px;
									margin: 7px 0 0;
								}
								@include respond(small) {
									height: 18px;
									margin: 6px 0 0;
								}
								@include respond(iphone) {
									height: 14px;
									margin: 9px 0 0;
								}
								img {
									max-height: 100%;
								}
							}
						}
						#type {
							@include respond(largeUp) {
								margin-left: 24%;
							}
                            @include respond(large) {
                                margin-left: 0;
                            }
						}
						& > .form-row {
							label {
								font-size: 0.6875rem;
							}
						}
					}
				}
                .payment-method {
                    padding-bottom: 0;
                    .form-row.cvn {
                        .form-field-tooltip {
							left: auto;
							margin: rem(16px) 0 0 rem(8px);
							position: relative;
							top: 0;
							float: left;
							width: auto;
                            @include respond(large) {
                                left: auto;
								position: relative;
								top: 0;
								margin: rem(16px) 0 0 rem(8px);
								float: left;
								width: auto;
                            }
                        }
                    }
                }
                input[type="radio"] {
                    @include respond(small) {
                        display: block;
                        float: none;
                    }
                }
			}
			.billing-coupon-code {
                .form-row.label-above {
                    @include respond(small) {
                        float: left;
                    }
                    .field-wrapper {
                        @include respond(large) {
                            width: 54%;
                        }
                    }
                }
                .coupon-error {
                    clear: both;
                }
                button#add-coupon {
                    @include respond(largeUp) {
                        top: 47px;
                        right: 104px;
                    }

                    @include respond(small) {
                        float: left;
                        margin-top: 47px;
                    }
                }
			}
            .kcp-options .icon img.logo {
                height: 26px;
            }
		}
	}
}
