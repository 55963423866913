#wrapper.pt_cart {
    #main {
        #primary {
              .name-container {
                .cart-name {
                  width: 30%;
                  @include respond(small) {
                      width: 100%;
                  }
                  position: relative;
                  .sp-banner {
                    @include respond(largeUp) {
                      position: absolute;
                    }
                    p {
                      font-size: 12px;
                      line-height: normal;
                      color: $color-secondary;
                    }
                  }
                  .cart-promo.cart-promo-approaching {
                    font-size: 12px;
                  }
                }
                .cart-actions-top {
                    width: 70%;
                    @include respond(small) {
                      width: 100%;
                    }
                    .cart-action-paypal {
                        width: auto;
                        max-width: none;
                        padding: 0 16px;
                        box-sizing: content-box;
                        @include respond(small) {
                            width: 100%;
                            box-sizing: border-box;
                        }
                        img {
                            display: inline-block;
                            @include respond(small) {
                                width: auto;
                                height: 32px;
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }
            #cart-items-form {
                .cart-footer {
                    .action-carousel {
                        .cart-actions {
                            .cart-promo.cart-promo-approaching {
                                font-size: 12px;
                            }
                            .cart-action-paypal {
                                width: 100%;
                                margin: 8px 0 0;
                            }
                        }
                    }
                }
            }
            ul.product-availability-list {
                li.on-order-message {
                    font-size: .9em;
                    line-height: normal;
                    color: $color-secondary;
                    padding-top: 0;
                }
            }
            .cart-actions {
                .cart-action-paypal {
                    background: none;
                    color: $color-secondary;
                    border: 1px solid $color-secondary;
                    &:hover {
                        color: $shiraz;
                        border: 1px solid $shiraz;
                    }
                    img {
                        width: auto;
                        height: 32px;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
