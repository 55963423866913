#pdpMain {
    .product-core-info + .product-info {
        margin-top: 0;
    }
    .configurator-form.configurator-regular .configurator-option .config-option-label {
        word-break: keep-all;
        line-height: 15px;
        font-size: 13px;
    }
}
