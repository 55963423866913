.ui-dialog {
    #dialog-container {
        #edit-address-form {
            .postal-code {
                position:relative;

                .form-field-tooltip {
                    display: block;
                    text-align: right;
                    margin-top: 0px;
                    padding-top: 0;
                    right: 0;
                    top: 9px;
                    position: absolute;

                    a {
                        color: #989898;
                    }
                }
            }

            .form-row {
                .form-caption {
                    margin-left: 20%;
                }

                label {
                    width: 18%;
                }
            }

            .form-field-tooltip {
                display: none;
            }
        }
    }
}

.account-menu .acct-links-orders {
    display: none;
}

nav.primary .left-content a {
    &:focus,
    &:focus-visible {
        outline: none;
    }
}

nav.primary .right-content .account-menu .acct-links-orders {
    display: none;
}
