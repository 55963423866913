#wrapper.pt_order {
	#main {
		.order-confirmation-details {
			table.item-list {
				tr {
					td:last-child.order-value {
						width: 51%;
					}
				}
			}
		}
	}
}

#wrapper.pt_order-confirmation {
	#main {
		.order-confirmation-details {
			table.item-list {
				tr {
					.order-totals-table td.order-value {
						width: 50%;
					}
				}
			}
		}
	}
}
